export const assets = {
  placeHolderLogo: 'assets/images/',
  logo: 'assets/images/logo.svg',
  loginbgimg: 'assets/images/login_page.jpg',
  logout: 'assets/images/logout.svg',
  profile: 'assets/images/profile_icon.svg',
  camera: 'assets/images/camera.png',
  browser: 'assets/images/browser.png',
  noDataFound: 'assets/images/Nodat-found.gif',
  deleteIcon: 'assets/images/delete_icon.svg',
  editIcon: 'assets/images/edit_icon.svg',
  web: 'assets/images/web.svg',
  qr: 'assets/images/qr.svg',
  f: 'assets/images/f.png',
  li: 'assets/images/li.png',
  x: 'assets/images/x.png',
  inst: 'assets/images/insta.png',
  yutb: 'assets/images/yu.png',
  call: 'assets/images/call.svg',
  mail: 'assets/images/email.svg',
  doc: 'assets/images/doc.svg',
  arrows: 'assets/images/arrows.png',
  cndlt: 'assets/images/cldnly_logo.svg',
  cndly: 'assets/images/cldnly_logo.png',
  instagram: 'assets/images/instagram.png',
  twitter: 'assets/images/twitter.png',
  tunix_clndly_lgoo:'assets/images/tunix_clndly_lgoo.png'
};
